import React, { useContext, useState } from "react";
import logo from "../images/logowhite.png";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import { Hamburger } from "./Hamburger";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";



const Iconbar = () => {
  const { currentUser, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [hamburger, setHamburger] = useState(false);
  const showHamburger = () => setHamburger(!hamburger);


  const logoutNavbar = () => {
    logout();
    navigate("/login");
  };

  
  return (
    <div id="-iconbar">
      <div className="icon-menu">
        <div className="logo-icon">
          <Link to='/'><img src={logo} alt="" /></Link>
        </div>
        <div title="Menu" className="-ham">
          <MenuIcon fontSize="large" title="Menu" onClick={showHamburger} />
        </div>
        <nav className={hamburger ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-item" onClick={showHamburger}>
            <li className="navbar-toggle">
              <div className="menu-bars">
                <CloseIcon fontSize="large" />
              </div>
            </li>
            {Hamburger.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link to={item.path}>
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>

          <div className="nav-icon">
          <a href="/">หน้าแรก</a>
          <a href="/howto">วิธีการใช้งาน</a>
          <a href="/contactus">ติดต่อเรา</a>
          {/* <a href="/register">สมัครสมาชิก</a> */}
          <span className="user-id">
            <AccountCircleIcon /> {currentUser?.username}
          </span>
          {currentUser ? (
            <span className="user-id" onClick={logoutNavbar}>
              {" "}
              <LogoutIcon /> ออกจากระบบ
            </span>
          ) : (
            <Link className="link" to="/login">
              เข้าสู่ระบบ / สมัครสมาชิก
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Iconbar;

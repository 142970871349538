import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import EmailIcon from "@mui/icons-material/Email";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import logo from "../images/logoblue.png";
import Swal from "sweetalert2";

const Register = () => {
  const [inputs, setInputs] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "", // เพิ่ม confirmPassword ใน state
  });

  const [err, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const validateInputs = () => {
    // Validate username length
    if (inputs.username.length < 4) {
      Swal.fire({
        title: 'Error',
        text: 'ชื่อผู้ใช้ต้องมีอย่างน้อย 4 ตัวอักษร',
        icon: 'warning',
      });
      return false;
    }

    // Validate email format
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(inputs.email)) {
      Swal.fire({
        title: 'Error',
        text: 'กรุณากรอกอีเมล์ให้ถูกต้อง',
        icon: 'warning',
      });
      return false;
    }

    // Validate password length
    if (inputs.password.length < 4) {
      Swal.fire({
        title: 'Error',
        text: 'รหัสผ่านต้องมีอย่างน้อย 4 ตัวอักษร',
        icon: 'warning',
      });
      return false;
    }

    // Validate that passwords match
    if (inputs.password !== inputs.confirmPassword) {
      Swal.fire({
        title: 'Error',
        text: 'รหัสผ่านไม่ตรงกัน',
        icon: 'warning',
      });
      return false;
    }

    return true; // Return true if all validations pass
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!loading && validateInputs()) {
      setLoading(true);

      try {
        await axios.post("https://api.austhaitown.com/api/auth/register", {
          username: inputs.username,
          email: inputs.email,
          password: inputs.password,
        });
        Swal.fire({
          title: 'ขอแสดงความยินดี',
          text: 'คุณได้สมัครเป็นสมาชิกกับเราเรียบร้อยแล้ว',
          icon: 'success',
          timer: 3500,
        });
        navigate("/login");
      } catch (err) {
        const errorMessage = err.response?.data || 'เกิดข้อผิดพลาดที่ไม่คาดคิด';
        Swal.fire({
          title: 'สมัครสมาชิกไม่สำเร็จ!',
          text: errorMessage,
          icon: 'error',
        });
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div id="-login">
      <form>
        <div><img src={logo} alt="" /></div>
        <h3>สมัครสมาชิก / Register</h3>
        <span>
          <PersonAddIcon fontSize="large" />
          <input
            required
            type="text"
            placeholder="ชื่อผู้ใช้"
            name="username"
            onChange={handleChange}
          />
        </span>
        <span>
          <EmailIcon fontSize="large" />
          <input
            required
            type="email"
            placeholder="อีเมล์"
            name="email"
            onChange={handleChange}
          />
        </span>
        <span>
          <LockPersonIcon fontSize="large" />
          <input
            required
            type="password"
            placeholder="รหัสผ่าน"
            name="password"
            onChange={handleChange}
          />
        </span>
        <span>
          <LockPersonIcon fontSize="large" />
          <input
            required
            type="password"
            placeholder="ยืนยันรหัสผ่าน"
            name="confirmPassword"
            onChange={handleChange}
          />
        </span>
        <button type="submit" onClick={handleSubmit}>
          <GroupAddIcon />
          สมัครสมาชิก
        </button>
        {err && <p>{err}</p>}
        <span>
          คุณมีบัญชีแล้วหรือยัง? <Link to="/login">เข้าสู่ระบบ</Link>
        </span>
      </form>
    </div>
  );
};

export default Register;

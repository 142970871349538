import React, { useEffect, useState } from 'react';

const cities = [
  { name: 'Sydney', query: 'Sydney' },
  { name: 'Melbourne', query: 'Melbourne' },
  { name: 'Brisbane', query: 'Brisbane' },
  { name: 'Adelaide', query: 'Adelaide' },
  { name: 'Darwin', query: 'Darwin' },
  { name: 'Perth', query: 'Perth' },
  { name: 'Hobart', query: 'Hobart' },
];

function Weather() {
  const [weatherData, setWeatherData] = useState({});
  const apiKey = '2e1e5864719a450299a103012242510';

  useEffect(() => {
    const fetchWeatherData = async () => {
      const data = {};

      for (const city of cities) {
        try {
          const response = await fetch(`http://api.weatherapi.com/v1/current.json?key=${apiKey}&q=${city.query}&aqi=no`);
          if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
          }
          const result = await response.json();
          data[city.name] = result.current; // ใช้ข้อมูลจาก API
        } catch (error) {
          data[city.name] = { error: error.message };
        }
      }

      setWeatherData(data);
    };

    fetchWeatherData();
  }, [apiKey]);

  return (
    <>
      {cities.map(city => (
        <div key={city.name} style={{ marginBottom: '20px' }}>
          <h3>{city.name}</h3>
          {weatherData[city.name] ? (
            weatherData[city.name].error ? (
              <p>{weatherData[city.name].error}</p>
            ) : (
              <div>
                <p>
                  Temperature: {Math.round(weatherData[city.name].temp_c)} °C<br />
                  Condition: {weatherData[city.name].condition.text}
                </p>
                <img 
                  src={weatherData[city.name].condition.icon} 
                  alt={weatherData[city.name].condition.text} 
                  style={{ width: '50px', height: '50px' }}
                />
              </div>
            )
          ) : (
            <p>Loading...</p>
          )}
        </div>
      ))}
    </>
  );
}

export default Weather;

import React from 'react'
import HomeIcon from '@mui/icons-material/Home';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LoginIcon from '@mui/icons-material/Login';

export const Hamburger = [
  {
    title: 'หน้าแรก',
    path: '/',
    icon: <HomeIcon/>,
    cName: 'nav-text'
  },
  {
    title: 'เข้าสู่ระบบ',
    path: '/login',
    icon: <LoginIcon />,
    cName: 'nav-text'
  },
  {
    title: 'สมัครสมาชิก',
    path: '/register',
    icon: <PersonAddIcon />,
    cName: 'nav-text'
  },
  {
    title: 'วิธีการใช้งาน',
    path: '/howto',
    icon: <HelpOutlineIcon />,
    cName: 'nav-text'
  },
  {
    title: 'ติดต่อเรา',
    path: '/contactus',
    icon: <ContactPhoneIcon />,
    cName: 'nav-text'
  }
];


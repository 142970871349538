import React from "react";
import { Link } from "react-router-dom";
import Lineads from "../images/lineads.jpg"

const Contactus = () => {
  return (
    <div id="contactus">
      <div className="about">
        <h1>ช่องทางการติดต่อ</h1>
        <h3>1.สนใจลงโฆษณา <Link to="">ดูพื้นที่ลงโฆษณา</Link></h3>
        <h3>2.แจ้งปัญหาการใช้งาน</h3>
        <h3>3.แสดงความคิดเห็น</h3>
        <h2>Email : Austhaitown@gmail.com</h2>
        <img src={Lineads} alt="" />
        <Link to="https://line.me/ti/p/Fg4PsIlwKt">สแกน QR Code หรือ คลิกที่ลิงค์</Link>   
        <h2>ติดต่อได้ตลอด 24 ชั่วโมง</h2>  
        </div>
    </div>
  );
};

export default Contactus;

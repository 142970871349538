import React from "react";
import Logo from "../images/logowhite.png";


const Footer = () => {
  return (
    <footer>
      <div id="footer">
        <img src={Logo} alt="logo" />
        <div className="icon-app">
          <b>ศูนย์กลางสังคมคนไทยในออสเตรเลีย</b>
          <p>Copyright © 2024 AUSTHAITOWN All Right Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

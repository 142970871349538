import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import logo from '../images/logoblue.png';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import Swal from "sweetalert2";

const Login = () => {
  const [inputs, setInputs] = useState({
    username: "",
    password: "",
    captcha: "", // ฟิลด์สำหรับเก็บค่าที่กรอกตัวเลขสุ่ม
  });

  const [captcha, setCaptcha] = useState(""); // สำหรับเก็บตัวเลขสุ่ม
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  // ฟังก์ชันสร้างตัวเลขสุ่ม
  const generateCaptcha = () => {
    const randomNum = Math.floor(1000 + Math.random() * 9000); // สุ่มเลข 4 หลัก
    setCaptcha(randomNum);
  };

  // เรียกฟังก์ชัน generateCaptcha เมื่อ component ถูก mount
  useEffect(() => {
    generateCaptcha();
  }, []);

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // ตรวจสอบว่าค่าที่กรอกใน captcha ตรงกับเลขสุ่มที่สร้างขึ้นหรือไม่
    if (inputs.captcha !== captcha.toString()) {
      Swal.fire({
        title: 'Error',
        text: 'กรุณากรอกเลขให้ถูกต้อง!',
        icon: 'warning',
      });
      return; // หยุดการทำงานถ้า CAPTCHA ไม่ถูกต้อง
    }

    try {
      await login({ username: inputs.username, password: inputs.password }); // พยายามเข้าสู่ระบบด้วยข้อมูลที่ให้
      navigate("/"); // นำทางไปยังหน้าหลักถ้าสำเร็จ
      window.location.reload(); // รีเฟรชหน้าเว็บ
    } catch (err) {
      console.error("Login error:", err); // ตรวจสอบว่ามีข้อผิดพลาดเกิดขึ้น
      Swal.fire({
        title: 'Error',
        text: 'Username หรือ Password ของคุณไม่ถูกต้อง!',
        icon: 'warning',
      });
    }
  };

  return (
    <div id="-login">
      <form onSubmit={handleSubmit}>
        <div className="logo"><img src={logo} alt="" /></div>
        <h3>เข้าสู่ระบบ / Sign in</h3>
        <span>
          <PersonIcon fontSize="large"/>
          <input
            type="text"
            placeholder="ชื่อผู้ใช้"
            name="username"
            onChange={handleChange}
            required
          />
        </span>
        <span>
          <LockIcon fontSize="large"/>
          <input
            type="password"
            placeholder="รหัสผ่าน"
            name="password"
            onChange={handleChange}
            required
          />
        </span>
        <div>
          <div>กรุณากรอกเลข: {captcha}</div>
          <input
            type="text"
            placeholder="กรอกตัวเลขที่เห็นด้านบน"
            name="captcha"
            onChange={handleChange}
            required
            style={{ textAlign: "center" }} 
          />
        </div>
        <button type="submit"><PowerSettingsNewIcon/>เข้าสู่ระบบ</button>
        <div>
          คุณไม่มีบัญชีใช่ไหม? <Link to="/register">สมัครสมาชิก</Link>
        </div>
      </form>
    </div>
  );
};

export default Login;

import { createContext, useEffect, useState } from "react";
import axios from "axios";

// สร้าง Context ใหม่ชื่อ AuthContext
export const AuthContext = createContext();

// ฟังก์ชันสำหรับการดึง JWT token จาก cookies
const getTokenFromCookies = () => {
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith('access_token='));
  
  if (cookieValue) {
    return cookieValue.split('=')[1]; // คืนค่า JWT token
  }
  return null; // คืนค่า null ถ้าไม่พบ
};

// นิยาม Component ชื่อ AuthContextProvider ที่รับ children เป็น props
export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("userID")) || null
  );

  const [errorMessage, setErrorMessage] = useState("");

  const login = async (inputs) => {
    try {
      const res = await axios.post("https://api.austhaitown.com/api/auth/login", inputs, { withCredentials: true });
      setCurrentUser(res.data); // ตั้งค่าผู้ใช้ปัจจุบัน
      setErrorMessage(""); // ล้างข้อความข้อผิดพลาดเมื่อเข้าสู่ระบบสำเร็จ
      
      // ดึง token จาก cookies
      const token = getTokenFromCookies();
      if (token) {
        console.log("Access Token from Cookies:", token);
        // เก็บ access_token ใน localStorage
        localStorage.setItem("access_token", token);
      }
    } catch (error) {
      console.error("Login failed:", error.response ? error.response.data : error.message);
      setErrorMessage(error.response?.data || "An error occurred during login.");
      throw error; // โยนข้อผิดพลาดเพื่อให้ handleSubmit จัดการต่อ
    }
  };
  
  const logout = async () => {
    try {
      await axios.post("https://api.austhaitown.com/api/auth/logout");
      setCurrentUser(null);
      localStorage.removeItem("access_token"); // ลบ access token เมื่อออกจากระบบ
    } catch (error) {
      console.error("Logout failed:", error.response ? error.response.data : error.message);
    }
  };

  useEffect(() => {
    if (currentUser) {
      localStorage.setItem("userID", JSON.stringify(currentUser));

      // ดึงและแสดง token จาก localStorage
      const accessToken = localStorage.getItem("access_token");
      if (accessToken) {
        console.log("JWT Token from localStorage:", accessToken);
      }
    } else {
      localStorage.removeItem("userID");
    }
  }, [currentUser]);

  return (
    <AuthContext.Provider value={{ currentUser, login, logout, errorMessage }}>
      {children}
    </AuthContext.Provider>
  );
};

import React, { useContext, useEffect, useState } from "react";
import EditImage from "../images/edit.png";
import DeleteImage from "../images/delete.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { AuthContext } from "../context/authContext";
import Swal from "sweetalert2";
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import ads from "../images/ads1.png";

const SydneySingle = () => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const postId = location.pathname.split("/")[2];
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.austhaitown.com/api/hobart/${postId}`);
        setPost(response.data);
        await axios.put(`https://api.austhaitown.com/api/hobart/view/${postId}`);
      } catch (error) {
        console.error('Error fetching post:', error);
        Swal.fire({
          title: "Error!",
          text: "ไม่สามารถดึงข้อมูลโพสต์ได้",
          icon: "error",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [postId]);

  // Fetch comments
  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await axios.get(`https://api.austhaitown.com/api/hobart/${postId}/comments`);
        setComments(response.data);
      } catch (error) {
        console.error('ดึงคอมเมนต์ล้มเหลว:', error);
      }
    };

    fetchComments();
  }, [postId]);

  const handleAddComment = async () => {
    if (!newComment.trim()) return; // ตรวจสอบไม่ให้คอมเมนต์ว่างเปล่า
    try {
      await axios.post(`https://api.austhaitown.com/api/hobart/${postId}/comment`, {
        comment: newComment,
        userId: currentUser.id
      });
      setComments(prev => [{ username: currentUser.username, comment: newComment, date: new Date() }, ...prev]);
      setNewComment(""); // ล้างข้อความในช่องคอมเมนต์
    } catch (error) {
      console.error('เพิ่มคอมเมนต์ล้มเหลว:', error);
    }
  };

  const handleDelete = async () => {
    const confirmed = await Swal.fire({
      title: "คุณแน่ใจหรือไม่?",
      text: "คุณจะไม่สามารถคืนค่าได้!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ใช่, ลบเลย!",
      cancelButtonText: "ไม่, ยกเลิก!",
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6"
    });

    if (confirmed.isConfirmed) {
      try {
        await axios.delete(`https://api.austhaitown.com/api/hobart/${postId}`);
        Swal.fire({
          title: "Deleted!",
          text: "Your post has been deleted.",
          icon: "success",
          timer: 3000,
        });
        navigate("/hobart");
      } catch (err) {
        console.error("Error deleting post:", err);
      }
    }
  };

  const openImageModal = (index) => {
    setSelectedImageIndex(index);
    setIsImageModalOpen(true);
    // Disable scrolling on body
    document.body.style.overflow = 'hidden';
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
    setSelectedImageIndex(0);
    // Enable scrolling on body
    document.body.style.overflow = 'auto';
  };

  const handleTouchStart = (event) => {
    const touch = event.touches[0];
    setTouchStartX(touch.clientX);
  };

  const handleTouchMove = (event) => {
    if (touchStartX === null) return;

    const touch = event.touches[0];
    const touchEndX = touch.clientX;

    if (touchStartX > touchEndX + 50) {
      // Swipe left
      setSelectedImageIndex((prevIndex) => (prevIndex + 1) % post.img.split(',').length);
      setTouchStartX(null);
    } else if (touchStartX < touchEndX - 50) {
      // Swipe right
      setSelectedImageIndex((prevIndex) => (prevIndex - 1 + post.img.split(',').length) % post.img.split(',').length);
      setTouchStartX(null);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (!post) return <div>Post not found</div>;

  return (
    <div id="-single">
      <img src={ads} alt="" />
      <button className="btn-back" onClick={() => navigate(-1)}>ย้อนกลับ</button>
      <div className="data">
        <h3 dangerouslySetInnerHTML={{ __html: post.title }} />
        <div dangerouslySetInnerHTML={{ __html: post.desc }} />
      </div>
      <div className="content">
        {post.img && post.img.split(',').length > 0 && (
          <div className="img-show" style={{ display: "flex", flexWrap: "wrap" }}>
            {post.img.split(',').map((image, index) => (
              <img
                key={index}
                src={`/upload/${image}`}
                alt={`uploaded-img-${index}`}
                style={{ width: "100px", height: "100px", margin: "10px", cursor: "pointer" }}
                onClick={() => openImageModal(index)} 
              />
            ))}
          </div>
        )}
        <div className="detail">
          <div className="item"><PersonIcon /> ผู้โพสต์: {post.username}</div>
          <div className="item"><PhoneIcon /> เบอร์โทรศัพท์: {post.phone}</div>
          <div className="item"><QrCode2Icon /> Line ID: {post.lineid}</div>
        </div>
      </div>
      <div className="user">
        <div className="info">
          <p>ผู้เข้าชม {post.view} ครั้ง⠀</p>
          <p>โพสต์เมื่อ {moment(post.date).fromNow()}</p>
          {currentUser?.username === post.username && (
            <div className="edit">
              <Link to={`/hobart/write?edit=2`} state={post}>
                <img src={EditImage} alt="edit" />
              </Link>
              <img onClick={handleDelete} src={DeleteImage} alt="delete" />
            </div>
          )}
        </div>
      </div>

      {isImageModalOpen && (
        <div className="image-modal" style={modalStyle}>
          <span onClick={closeImageModal} style={closeButtonStyle}>&times;</span>
          <div
            className="modal-content"
            style={modalContentStyle}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
          >
            <img src={`/upload/${post.img.split(',')[selectedImageIndex]}`} alt="expanded" style={expandedImageStyle} />
          </div>
        </div>
      )}
      {/* Comments Section */}
      <div className="comments-section">
        <h4>ความคิดเห็น / Comments</h4>
        {comments.length > 0 ? (
          comments.map((comment, index) => (
            <div key={index} className="comment">
              <strong>{comment.username}</strong> <span>โพสต์เมื่อ {moment(comment.date).fromNow()}</span>
              <p>{comment.comment}</p>
            </div>
          ))
        ) : (
          <p>ยังไม่มีคอมเมนต์เลย มาเป็นคนแรกที่คอมเมนต์สิ!</p>
        )}
      </div>

      {currentUser ? (
        <div className="add-comment">
          <textarea
            rows="3"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="เพิ่มคอมเมนต์..."
          ></textarea>
          <button onClick={handleAddComment}>โพสต์คอมเมนต์</button>
        </div>
      ) : (
        <p>กรุณาเข้าสู่ระบบเพื่อคอมเมนต์ <Link to="/login">Sing in</Link></p>
      )}
    </div>
  );
};

// สไตล์สำหรับ modal
const modalStyle = {
  position: 'fixed',
  zIndex: 999,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  backgroundColor: 'rgba(0,0,0,0.8)',
};

const closeButtonStyle = {
  position: 'absolute',
  top: '10px',
  right: '25px',
  color: 'white',
  fontSize: '40px',
  fontWeight: 'bold',
  cursor: 'pointer',
};

const modalContentStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
};

const expandedImageStyle = {
  maxWidth: '90%',
  maxHeight: '90%',
};

// Export component
export default SydneySingle;

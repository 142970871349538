import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import "moment/locale/th";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { AuthContext } from "../context/authContext";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { sliderData } from "../components/sliderData";
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';

const Sydney = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideLength = sliderData.length;

  const autoScroll = true;
  let slideInterval;
  const intervalTime = 8000;

  const nextSlide = () => {
    setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slideLength - 1 : currentSlide - 1);
  };

  const auto = () => {
    slideInterval = setInterval(nextSlide, intervalTime);
  };

  useEffect(() => {
    setCurrentSlide(0);
    if (autoScroll) {
      auto();
    }
    return () => clearInterval(slideInterval);
  }, []);

  const { currentUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [posts, setPosts] = useState([]);
  const cat = useLocation().search;

  // ดึงโพสต์จาก API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`https://api.austhaitown.com/api/brisbane${cat}`);
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [cat]);

  // เรียงโพสต์ตามวันที่
  useEffect(() => {
    const sortedPosts = posts.sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );
    setData(sortedPosts);
  }, [posts]);

  const [imagesPerPage] = useState(20);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setData(posts.slice(0, page * imagesPerPage));
  }, [page, posts]);

  // ฟังก์ชันค้นหา
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = async (e) => {
    e.preventDefault();
    if (searchTerm.length > 0) {
      try {
        const res = await axios.get(`https://api.austhaitown.com/api/brisbane/search?q=${searchTerm}`);
        setPosts(res.data);
      } catch (err) {
        console.error("Error fetching search results:", err);
      }
    }
  };

  const [selectedCategory, setSelectedCategory] = useState("");

  return (
    <div id="-sydney">
      <form className="search" onSubmit={handleSearch}>
        <SearchIcon />
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="ค้นหา..."
        />
        <button type="submit">ค้นหา</button>
      </form>

      <div className="write">
        {currentUser && (
          <Link className="link" to="/brisbane/write">
            <PostAddIcon fontSize="large" /> สร้างโพสต์
          </Link>
        )}
      </div>
      <div className="-tags">
        {["ห้องว่าง", "หาบ้าน", "รับสมัครงาน", "หางาน", "รับทำการบ้าน", "ซื้อ", "ขาย", "รับขนของ", "อื่นๆ"].map((category) => (
          <div className="tags" key={category}>
            <Link className="link" to={`/brisbane/?cat=${category}`}>
              <h6 onClick={() => setSelectedCategory(category)}>{category}</h6>
            </Link>
          </div>
        ))}
      </div>

      <div className="slider">
        <AiOutlineArrowLeft className="arrow prev" onClick={prevSlide} />
        <AiOutlineArrowRight className="arrow next" onClick={nextSlide} />
        {sliderData.map((slide, index) => (
          <div
            className={index === currentSlide ? "slide current" : "slide"}
            key={index}
          >
            {index === currentSlide && (
              <img src={slide.image} alt="slide" className="image" />
            )}
          </div>
        ))}
      </div>

      <div className="posts">
        {data.map((post) => (
          <div className="post" key={post.id}>
            <div className="top">
              <div className="cat-post">{post.cat}</div>
              <div className="view"><VisibilityIcon /> {post.view}</div>
            </div>
            <div className="content">
              <div dangerouslySetInnerHTML={{ __html: post.title }} />
            </div>
            <div className="button-item">
              <AccessTimeIcon /> {moment(post.date).fromNow()}
              <Link className="link" to={`/brisbane/${post.id}`}>
                <button>อ่านเพิ่ม...</button>
              </Link>
            </div>
          </div>
        ))}
      </div>

      <div className="load-more">
        {data.length < posts.length && (
          <button className="btn-load" onClick={() => setPage(page + 1)}>
            ดูโพสต์เพิ่มเติม..
          </button>
        )}
      </div>
    </div>
  );
};

export default Sydney;

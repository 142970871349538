import React from 'react'
import home from "../images/1.jpg"
import single from "../images/2.jpg"
import login from "../images/3.jpg"
import addpost from "../images/4.jpg"
import post from "../images/5.jpg"
import confirm from "../images/6.jpg"
import Edit from "../images/7.jpg"

const Howto = () => {
  return (
    <div className='howto'>
    <h1>ขั้นตอนการสร้างโพสต์</h1>
    <h2>1.เมื่อเข้าหน้าเว็บไซต์จะมีเมืองให้เลือกจากนั้นกดเข้าที่รูปเมืองที่เราต้องการ</h2>
    <img src={home} alt="" />
    <h2>2.จะมีหัวข้อโพสต์ให้เราเลือกและโพสต์ต่างๆเราสามารถกดดูเนื้อหาภายในได้หรือต้องการค้นหาสามารถค้นหาได้ที่ช่องค้นหา</h2>
    <img src={single} alt="" />
    <h2>3.เมื่อเราต้องการสร้างโพสต์ให้เราสมัครสมาชิกและล็อคอินก่อน</h2>
    <img src={login} alt="" />
    <h2>4.เมื่อล็อคอินเสร็จแล้วเลือกเมืองจะมีฟังก์ชั่นสร้างโพสต์ขึ้นมาด้านบนหัวข้อโพสต์</h2>
    <img src={addpost} alt="" />
    <h2>5.กรอกข้อมูลลงไปในช่องว่างให้ครบถ้วนและสามารถอัพรูปได้สูงสุด 6 ภาพเท่านั้น</h2>
    <img src={post} alt="" />
    <h2>6.กดที่ "สร้างโพสต์" และยืนยันการสร้างโพสต์</h2>
    <img src={confirm} alt="" />
    <h2>7.หากต้องการแก้ไขโพสต์ของตัวเองให้กดเข้าไปที่โพสต์ของคุณและคลิกที่ icon แก้ไข หรือ ลบ โพสต์</h2>
    <img src={Edit} alt="" />
    </div>
  )
}

export default Howto
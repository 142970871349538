import React, { useEffect, useState } from "react";
import sydney from "../images/sydney.jpeg";
import melbourne from "../images/melbourne.webp";
import brisbane from "../images/brisbane.webp";
import perth from "../images/perth.jpg";
import adelaide from "../images/adelaide.webp";
import darwin from "../images/darwin.jpg";
import hobart from "../images/hobart.webp";
import { Link, useLocation } from "react-router-dom";
import InfoIcon from '@mui/icons-material/Info';
import axios from "axios";

const cities = [
  { name: "sydney", label: "Sydney, New South Wales", query: 'Sydney', img: sydney },
  { name: "melbourne", label: "Melbourne, Victoria", query: 'Melbourne', img: melbourne },
  { name: "brisbane", label: "Brisbane, Queensland", query: 'Brisbane', img: brisbane },
  { name: "perth", label: "Perth, Western Australia", query: 'Perth', img: perth },
  { name: "adelaide", label: "Adelaide, South Australia", query: 'Adelaide', img: adelaide },
  { name: "darwin", label: "Darwin, Northern Territory", query: 'Darwin', img: darwin },
  { name: "hobart", label: "Hobart, Tasmania", query: 'Hobart', img: hobart },
];

const Main = () => {
  const [posts, setPosts] = useState([]);
  const [totals, setTotals] = useState({});
  const [exchangeRates, setExchangeRates] = useState({ AUD: 0, THB: 0, USD: 0 });
  const cat = useLocation().search;

  const fetchPosts = async (city) => {
    try {
      const res = await axios.get(`https://api.austhaitown.com/api/${city}${cat}`);
      setPosts(prevPosts => [...prevPosts, ...res.data]);
      setTotals(prevTotals => ({ ...prevTotals, [city]: res.data.length }));
    } catch (err) {
      console.log(err);
    }
  };

  // Fetch exchange rates
  const fetchExchangeRates = async () => {
    try {
      const res = await axios.get("https://api.exchangerate-api.com/v4/latest/AUD"); // เปลี่ยน URL ตาม API ที่คุณใช้
      const { rates } = res.data;
      setExchangeRates({
        AUD: rates.AUD,
        THB: rates.THB,
        USD: rates.USD,
      });
    } catch (err) {
      console.error('Error fetching exchange rates:', err);
    }
  };

  useEffect(() => {
    fetchExchangeRates(); // ดึงอัตราแลกเปลี่ยนเมื่อคอมโพเนนต์โหลด
    cities.forEach(city => fetchPosts(city.name));
  }, [cat]);

  // ข้อมูลสภาพอากาศ
  const [weatherData, setWeatherData] = useState({});
  const apiKey = '2e1e5864719a450299a103012242510';

  useEffect(() => {
    const fetchWeatherData = async () => {
      const data = {};

      for (const city of cities) {
        try {
          const response = await fetch(`https://api.weatherapi.com/v1/current.json?key=${apiKey}&q=${city.query}&aqi=no`);
          if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
          }
          const result = await response.json();
          data[city.name] = result.current; // ใช้ข้อมูลจาก API
        } catch (error) {
          data[city.name] = { error: error.message };
        }
      }

      setWeatherData(data);
    };

    fetchWeatherData();
  }, [apiKey]);

  return (
    <>
      <div id="main-page">
        <div className="text-news">
          <InfoIcon />
          <marquee scrollamount="5" direction="left">
            AUSTHAITOWN คนไทยในออสเตรเลีย หาบ้าน หางาน หาเงิน หาโรงแรม ขายของ หาเพื่อน หาแฟน หาเอเจนต์ จ้างทำการบ้าน สมัครสมาชิกแล้วโพสต์ได้เลย
          </marquee>
        </div>
        <div className="exchange-rates">
          <h4>อัตราแลกเปลี่ยนเงินวันนี้ 1 AUD = {exchangeRates.THB} THB | 1 AUD = {exchangeRates.USD} USD</h4>
        </div>
        <div className="city-town">
          {cities.map(city => (
            <div key={city.name} className="-city-town">
              <Link to={`/${city.name}`}>
                <img src={city.img} alt={city.label} />
                {weatherData[city.name] ? (
            weatherData[city.name].error ? (
              <p>{weatherData[city.name].error}</p>
            ) : (
              weatherData[city.name].temp_c !== undefined && weatherData[city.name].condition ? (
                <div>
                  <p className="-temperature">
                    Temperature: {Math.round(weatherData[city.name].temp_c)} °C<br />
                    Condition: {weatherData[city.name].condition.text}
                  </p>
                </div>
              ) : (
                <p>No weather data available</p>
              )
            )
          ) : (
            <p>Loading...</p>
          )}
                <p>{city.label}</p>
                <p>จำนวน {totals[city.name] || 0} โพสต์</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Main;
